@import "../../../node_modules/@acme/web-ui-kit/src/styles/breakpoints";
@import "../../styles/colors";

.container {
  position: relative;
}

.label {
  display: flex;
  align-items: center;
  padding: 0 8px 0 0;
  gap: 8px;
}

.icon {
  width: 18px;
  height: 12px;
  border-radius: 2px;
}

@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/global";

.container {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 20px;
}

.icon {
  width: 60px;
  height: 60px;
  background: 100% 100% / contain no-repeat url("./assets/success.svg");
}

.description {
  color: map.get($green, "300");
  text-align: center;
}

.divider {
  width: 100%;

  &:first-child {
    margin: 40px 0;
  }

  &:last-child {
    margin: 40px 0 0;
  }
}

@use "sass:map";

@import "src/styles";
@import "../../../node_modules/@acme/web-ui-kit/src/styles/global";

.footer {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
}

.footerContainer {
  flex-flow: column nowrap;
}

.divider {
  margin: 40px 0;
}

.footerContent {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 30px;
  padding: 0 0 40px;
  align-items: center;
}

.footerLogo {
  position: relative;
  width: 160px;
  height: 60px;
  background: center / contain url("./assets/ownr-logo.svg") no-repeat;
  flex-shrink: 0;

  @include media-max($sm) {
    width: 80px;
  }
}

.footerLicense {
  max-width: #{map.get($dimensions, "maxWidth")}px;
  color: $textSecondary;
}

@use "sass:map";

@import "../../../node_modules/@acme/web-ui-kit/src/styles/breakpoints";
@import "../../../node_modules/@acme/web-ui-kit/src/styles/colors";

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: map.get($black, "900");
  margin: 0 auto;
  max-width: 730px;
  padding: 0 24px;

  @include media-max($sm) {
    padding: 0 16px;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 80px;
  margin-top: 40px;

  @media print {
    margin-bottom: 20px;
  }
}

.headerLogo {
  height: 60px;
}

.main {
  flex: auto;
}

.invoiceDetails {
  margin-bottom: 20px;
}

.invoiceDetailLabel {
  padding-right: 10px;
}

.divider {
  border-top: 5px dotted map.get($black, "300");
  margin: 50px 0;

  @media print {
    border: none;
    margin: 20px 0;
  }
}

.orderExpiredMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.orderExpiredMessage {
  color: map.get($danger, "500");
}

.paymentDetailsHeader {
  display: inline-block;
  margin-bottom: 30px;

  @media print {
    margin-bottom: 10px;
  }
}

.paymentDetailsAdditionalInfo {
  display: inline-block;
  color: map.get($black, "700");
  margin: 40px 0;

  @media print {
    display: none;
  }
}

.footer {
  flex: 0 0;
  padding-bottom: 40px;

  @media print {
    display: none;
  }
}

.footerLicense {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  color: map.get($black, "500");
}

.footerLogo {
  height: 40px;
}

@use "sass:map";

@import "../../../node_modules/@acme/web-ui-kit/src/styles/global";

.container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 20px 30px;
  background: map.get($black, "600");
  border-radius: 10px;
}

.containerActive {
  background: map.get($black, "700");
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.title {
  color: map.get($black, "white");
}

.radio {
  ::before {
    background-color: transparent;
    margin: 0;
    border-width: 1px;
    border-color: map.get($black, "white");
    color: map.get($black, "white");
  }

  :checked + ::before {
    border-width: 2px;
    background: radial-gradient(currentcolor 50%, transparent 0) !important;
  }
}

.radioDisabled {
  display: none;
}

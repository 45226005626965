@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700&display=swap";

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family:
    Montserrat,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121a25;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

apple-pay-button {
  display: block;

  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 50px;
  --apple-pay-button-border-radius: 5px;
  --apple-pay-button-padding: 0 0;
  --apple-pay-button-box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

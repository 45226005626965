$primaryMain: #5f46f9;
$primaryLight: #858aff;
$errorMain: #d32f2f;
$successMain: #2e7d32;
$darkColor: #262241;
$backgroundColor: #f3f6f9;
$textPrimary: #121a25;
$textSecondary: #89919d;
$error: #f44336;
$buttonHover: #4231ae;

@use "sass:math";
@use "sass:list";

$fiatCurrencies: (
  ("XXX",
  "BYN",
  "CAD",
  "CHF",
  "CNY",
  "CZK",
  "DKK",
  "EUR",
  "GBP",
  "KZT",
  "NOK",
  "NZD",
  "PLN",
  "RUB",
  "SEK",
  "UAH",
  "USD",
  "AED",
  "AMD",
  "ARS"
),
("AZN",
"BAM",
"BDT",
"BGN",
"BHD",
"BND",
"BOB",
"BRL",
"CLP",
"COP",
"DJF",
"DZD",
"EGP",
"GEL",
"GIP",
"GYD",
"HKD",
"HRK",
"HUF",
"IDR"
),
("ILS",
"INR",
"ISK",
"JOD",
"JPY",
"KES",
"KGS",
"KRW",
"KWD",
"LYD",
"MDL",
"MKD",
"MXN",
"MYR",
"NGN",
"OMR",
"PEN",
"PHP",
"QAR",
"RON"
),
("RSD",
"SAR",
"SGD",
"THB",
"TJS",
"TMT",
"TRY",
"TWD",
"TZS",
"UZS",
"XOF",
"ZAR",
"AUD",
"ALL",
"VND",
"",
"",
"",
"",
""
)
);

@mixin common-background-position($collection) {
  $row-count: max(list.length($collection), 2);

  @each $row in $collection {
    $row-length: max(list.length($row), 2);

    @each $name in $row {

      @if $name != "" {
        &.#{$name} {
          background-position:
            100% * math.div((list.index($row, $name) - 1), ($row-length - 1))
            100% * math.div(
              (list.index($collection, $row) - 1),
              ($row-count - 1)
            );
        }
      }
    }
  }
}

@mixin common-icon-sizes($collection, $width, $height) {
  width: $width;
  height: $height;
  background-size:
    ($width + 10) * list.length(list.nth($collection, 1)) - 10
    ($height + 10) * list.length($collection) - 10;
}

$iconSmallSize: 30px;

@mixin fiat-currency {

  @include common-background-position($fiatCurrencies);

  &.small {
    background-image: url("../../../assets/fiat-icons.svg");

    @include common-icon-sizes($fiatCurrencies,
    $iconSmallSize,
    $iconSmallSize );
  }
}

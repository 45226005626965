@use "sass:map";

@import "../../../../../node_modules/@acme/web-ui-kit/src/styles/global";

.container {
  display: flex;
  flex-flow: row wrap;
  gap: 40px;
  padding: 30px 0 15px;

  @include media-max($sm) {
    flex-direction: column;
  }
}

.cardContainer {
  flex: 2;
  display: flex;
  flex-flow: row nowrap;
}

.holderNameContainer {
  flex: 1;
}

.panInputContainer {
  width: auto;
  flex: 3;
}

.panInput {
  width: 100%;

  input {
    color-scheme: dark;
    box-shadow: 0 0 0 1000px map.get($black, "700") inset;
  }
}

.error {
  color: map.get($danger, "200");
}

.expiryDateInput {
  width: auto;
  flex: 1;

  input {
    color-scheme: dark;
    box-shadow: 0 0 0 1000px map.get($black, "700") inset;
  }
}

.cvvInput {
  width: auto;
  flex: 1;

  input {
    color-scheme: dark;
    box-shadow: 0 0 0 1000px map.get($black, "700") inset;
  }
}

.holderNameInput {
  width: auto;

  input {
    text-transform: uppercase;
    color-scheme: dark;
    box-shadow: 0 0 0 1000px map.get($black, "700") inset;
  }
}

%card {
  width: 30px;
  height: 20px;

  @include media-max($sm) {
    width: 20px;
    height: 15px;
  }
}

.cardVisa {

  @extend %card;

  background: 100% 100% / contain url("./assets/cardtype-visa.png") no-repeat;
}

.cardMastercard {

  @extend %card;

  background: 100% 100% / contain url("./assets/cardtype-mastercard.png") no-repeat;
}

.cardMaestro {

  @extend %card;

  background: 100% 100% / contain url("./assets/cardtype-maestro.png") no-repeat;
}

.cardMir {

  @extend %card;

  background: 100% 100% / contain url("./assets/cardtype-mir.png") no-repeat;
}

.cardNone {

  @extend %card;

  background: 100% 100% / contain url("./assets/cardtype-none.png") no-repeat;
}

@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/global";
@import "../../../styles";

body {
  background-color: map.get($black, "100");
}

.container {
  display: flex;
  background-color: map.get($black, "100");
  min-height: 100vh;
  flex-flow: column nowrap;
  align-items: space-between;
  padding: 0 16px;
}

.topContent {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.header,
.footer,
.content {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
}

.logoContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
}

.logoDivider {
  height: 60px;
  width: 1px;
  background: map.get($black, "700");
}

.coBrandLogo {
  position: relative;
  width: 290px;
  height: 60px;
  background: center / contain url("./assets/cryptotab-browser-logo.svg") no-repeat;
  flex-shrink: 0;

  @include media-max($sm) {
    width: 145px;
  }
}

.logo,
.footerLogo {
  position: relative;
  width: 160px;
  height: 60px;
  background: center / contain url("./assets/ownr-logo.svg") no-repeat;
  flex-shrink: 0;

  @include media-max($sm) {
    width: 80px;
  }
}

.contentContainer {
  box-sizing: border-box;
  max-width: 100%;
}

.footerLogo {
  width: 110px;
  height: 40px;
}

.footerContainer {
  flex-flow: column nowrap;
}

.footerContent {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 30px;
  padding: 0 0 40px;
  align-items: center;
}

.footerLicense {
  max-width: #{map.get($dimensions, "maxWidth")}px;
  color: $textSecondary;

  @include media-max($md) {
    display: none;
  }
}

.content {
  min-height: calc(100vh - 90px - 110px);

  @include media-max($md) {
    min-height: calc(100vh - 90px - 54px);
  }
}

.divider {
  margin: 40px 0;
}

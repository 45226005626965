@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/global";

.input {
  width: auto;

  input {

    @include h4Normal;

    height: 30px;
  }
}

.button {
  min-height: 20px;
  min-width: 20px;
  padding: 0;
  line-height: 0;
  border: none;
  border-radius: 0;
  mask-image: url("../../../assets/copy.svg");
  background-color: map.get($black, "300");

  &:not(:disabled):hover {
    background-color: map.get($primary, "300");
  }

  &:not(:disabled):active {
    background-color: map.get($primary, "500");
  }
}

@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/colors";
@import "../../../../node_modules/@acme/web-ui-kit/src/styles/typography";
@import "../../../../node_modules/@acme/web-ui-kit/src/styles/breakpoints";
@import "./fiatCurrencies";
@import "./cryptoCurrencies";

.orderDetailsHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @media print {
    margin-bottom: 20px;
  }
}

.orderDetailsAmounts {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 20px;

  @include media-max("740px") {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.amount {
  width: 100%;

  & input {

    @include h4Normal;

    line-height: 20px;
  }
}

.coinContainer {
  display: flex;
  gap: 5px;
}

.cryptoCoin {
  transform: scale(0.67) translateY(25%);
  transform-origin: 0 0;

  @include crypto-currency;
}

.fiatCoin {
  transform: scale(0.67) translateY(25%);
  transform-origin: 0 0;

  @include fiat-currency;
}

.orderDetailsAdditionalInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: map.get($black, "700");
  margin-top: 30px;

  @media print {
    display: none;
  }
}

.address input {
  text-overflow: ellipsis;
}

@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/colors";

.userRequisites {
  display: flex;
  flex-direction: column;
}

.userRequisiteLabel {
  color: map.get($black, "500");
  margin-top: 20px;
}

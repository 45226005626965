@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/colors";

.select {

  @media print {
    display: none;
  }
}

.label {
  display: flex;
  gap: 11px;
  padding-right: 5px;
}

.languageIcon {
  height: 18px;
  width: 18px;
}

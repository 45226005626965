@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/global";

.container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
}

.title {
  font-weight: 700;
  line-height: 50px;
  color: map.get($black, "900");
}

.timer {
  display: flex;
  flex-flow: row-reverse nowrap;
  gap: 8px;
  align-items: center;
}

.timerIcon {
  font-size: 16px;
  color: map.get($primary, "900");
}

.timerText {
  font-weight: 700;
  color: map.get($danger, "500");
}

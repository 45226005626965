@use "sass:map";

@import "../../../node_modules/@acme/web-ui-kit/src/styles/global";

.actionList {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 40px;
}

.link {
  display: inline-block;
  text-decoration: none;
}

.button {
  display: inline-block;
  min-width: 150px;
}

.payButton {
  display: flex;
  gap: 11px;
  justify-content: center;
}

.payIcon {
  font-size: 20px;
  color: map.get($primary, "100");
}

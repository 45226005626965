@use "sass:list";

$smallIconSize: 30px;
$coins: (
  (
    "",
    "BCH",
    "BSV",
    "ETH",
    "LTC",
    "DOGE",
    "TRX",
    "DASH",
    "ETC",
    "omni",
    "EOS",
    "",
    "",
    "",
    "",
    "erc20",
    "",
    "",
    "",
    "USDT"
  ),
  (
    "USDT-TRX",
    "",
    "trc20",
    "",
    "",
    "",
    "LEO",
    "",
    "ZEC",
    "",
    "eos-jungle",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "bitcoin-testnet",
    "bitcoin-cash-testnet"
  ),
  (
    "bitcoin-sv-testnet",
    "ethereum-ropsten",
    "litecoin-testnet",
    "dogecoin-testnet",
    "tron-testnet",
    "dash-testnet",
    "",
    "zcash-testnet",
    "solana",
    "",
    "solana-testnet",
    "erc20-testnet-token",
    "tron-testnet-token",
    "BTC",
    "",
    "",
    "",
    "",
    "",
    ""
  )
);

@mixin create-crypto-currency-icons($iconSize, $stepY) {

  @each $rowName in $coins {

    @each $name in $rowName {

      @if $name != "" {
        &.#{$name} {
          background-position:
            (-$iconSize - 10px) * (list.index($rowName, $name) - 1)
            (list.index($coins, $rowName) - 1) * -110px - $stepY;
        }
      }
    }
  }
}

@mixin crypto-currency {
  background-image: url("../../../assets/currency-icons.svg");

  &.small {
    width: $smallIconSize;
    height: $smallIconSize;

    @include create-crypto-currency-icons($smallIconSize, 71);
  }
}

@use "sass:map";

@import "../../../node_modules/@acme/web-ui-kit/src/styles/global";

.container {
  border-top: 5px dotted map.get($black, "300");

  @media print {
    border: none;
  }
}

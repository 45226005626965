@import "../../styles/colors";

.wrapper {
  position: relative;
  min-height: 100%;
}

.progress {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1201;
  background-color: rgb(255 255 255 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.progressTitle {
  color: $primaryLight;
  font-weight: 500;
  margin-top: 16px;
}

@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/global";
@import "../../../styles/colors";

.container {
  display: flex;
  flex-flow: column nowrap;
  gap: 40px;
}

.paymentMethods {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
}

.textField.textField {
  margin-top: 8px;
  margin-bottom: 16px;

  input::-webkit-date-and-time-value {
    text-align: left;
  }
}

.link {
  color: map.get($link, "300");

  &:hover {
    color: map.get($link, "400");
    text-decoration: underline;
  }
}

.additionalFields {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 30px;
  gap: 10px;
  border: 1px solid rgb(146 147 176 / 50%);
  border-radius: 10px;

  & > div {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }
}

.errorContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
}

.errorIcon {
  font-size: 20px;
  color: map.get($danger, "500");
}

.errorText {
  color: map.get($danger, "500");
}

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/breakpoints";

.actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @include media-max($sm) {
    flex-direction: column-reverse;
  }

  @media print {
    display: none;
  }
}

.button {
  width: 180px;

  @include media-max($sm) {
    width: 100%;
  }
}

.printButtonContent {
  display: flex;
  justify-content: center;
}

.printIcon {
  padding-right: 10px;
}

.link {
  display: block;
  text-decoration: none;
}

@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/global";
@import "./fiatCurrencies";
@import "./cryptoCurrencies";

.container {
  display: flex;
  flex-flow: column nowrap;
}

.orderDetailsAmounts {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  gap: 50px;
  margin-bottom: 20px;

  @include media-max($sm) {
    flex-direction: column;
    gap: 20px;
  }
}

.amount {
  flex: 1;

  input {

    @include h4Normal;
  }
}

.fiatAmount {
  input {
    font-weight: 700;
  }
}

.coinContainer {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
}

.cryptoCoin {
  transform: scale(0.67);
  transform-origin: 0 0;

  @include crypto-currency;
}

.fiatCoin {
  transform: scale(0.67);
  transform-origin: 0 0;

  @include fiat-currency;
}

.currencyText {
  line-height: 20px;
}

.currencyIconContainer {
  position: relative;
  width: 20px;
  height: 20px;
}

.payoutAddress {
  input {
    padding-right: 5px;
    text-overflow: ellipsis;
  }
}

.divider {
  margin: 40px 0;
}

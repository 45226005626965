.container {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  align-items: center;
}

%icon {
  background: #ffffff;
  padding: 0 8px;
  border-radius: 3px;

  &::before {
    display: block;
    width: 50px;
    height: 30px;
    content: "";
    background: 50% 50% / contain no-repeat;
  }
}

.visaIcon {

  @extend %icon;

  &::before {
    background-image: url("./assets/visa.svg");
  }
}

.mastercardIcon {

  @extend %icon;

  &::before {
    background-image: url("./assets/mastercard.svg");
  }
}

.maestroIcon {

  @extend %icon;

  &::before {
    background-image: url("./assets/maestro.svg");
  }
}

.mirIcon {

  @extend %icon;

  &::before {
    background-image: url("./assets/mir.svg");
  }
}

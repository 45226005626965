@use "sass:map";

@import "../../../../node_modules/@acme/web-ui-kit/src/styles/colors";

.timerWrapper {
  display: flex;
  align-items: center;
  width: 110px;

  @media print {
    display: none;
  }
}

.timerIcon {
  height: 16px;
  width: 16px;
  margin-right: 12px;
}

.timer {
  color: map.get($danger, "500");
}

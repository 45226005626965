.container {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  align-items: center;
}

%icon {
  width: 55px;
  height: 30px;
}

.googlepayIcon {

  @extend %icon;

  background: 100% 100% / contain url("./assets/googlepay.svg") no-repeat;
}

@use "sass:map";

@import "../../../../../node_modules/@acme/web-ui-kit/src/styles/colors";

.button {
  padding: 8px 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: map.get($black, "600");
  }
}
